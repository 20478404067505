import React from "react";

import AccessibilityReport from "../components/AccessibilityReport/AccessibilityReport";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import useA11y from "../hooks/a11yReport";

export default function AccessibilityReportPage() {
  const {
    a11yinstantiation,
    a11ycontactphone,
    a11ycontactemail,
    a11yresponsetime,
    a11ycontacturi,
    a11yorganisation,
    a11ywebsite,
    a11ystatus,
    a11ytestmethod,
    a11ytestmethodauditurl,
    a11ytestmethodreporturl,
    a11yambitiondate,
    a11yreviewdate,
    a11ypublisheddate,
    a11ydeficiency,
  } = useA11y();
  return (
    <Layout title="Tillgänglighet för webbplatsen">
      <SEO title="Tillgänglighet för webbplatsen" />
      <AccessibilityReport
        a11yStatus={a11ystatus}
        a11yWebsite={a11ywebsite}
        a11yOrganisation={a11yorganisation}
        a11yContactUri={a11ycontacturi && a11ycontacturi.uri}
        a11yResponseTime={a11yresponsetime}
        a11yContactEmail={a11ycontactemail}
        a11yContactPhone={a11ycontactphone}
        a11yTestMethod={a11ytestmethod}
        a11yTestMethodAuditUrl={a11ytestmethodauditurl}
        a11yTestMethodReportUrl={a11ytestmethodreporturl}
        a11yPublishedDate={a11ypublisheddate}
        a11yReviewDate={a11yreviewdate}
        a11yInstantiation={a11yinstantiation}
        a11yAmbitionDate={a11yambitiondate}
        a11yDeficiency={a11ydeficiency}
      />
    </Layout>
  );
}
